:root {
  --text-color: #f3f3f3;
  --bg-color: #212121;
  --border: 2px solid rgba(230, 230, 230, 0.05);
  --highlight-color: rgb(140, 199, 239);
}

* {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h2 {
  font-weight: 800;
  font-size: 6rem;
  cursor: default;
  filter: brightness(0.9);
  transition: 200ms;
}

h2:hover {
  filter: brightness(1);
}

h5 {
  font-size: 1.2rem;
}

h6 {
  font-weight: 400;
  color: var(--highlight-color);
  letter-spacing: 0.01em;
}

p {
  font-size: 0.85rem;
  opacity: 0.8;
  font-weight: 300;
}

ul {
  margin: 0;
  list-style: none;
  padding: 0;
}

a {
  text-decoration: none;
  color: var(--text-color);
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.fa-2x {
  font-size: 1.3em;
  opacity: 0.1;
}

body {
  min-width: 100%;
  overflow: auto;
  color: var(--text-color);
  background: var(--bg-color);
  background-color: #060c0e;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1200 800'%3E%3Cdefs%3E%3CradialGradient id='a' cx='0' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%231e2e2d'/%3E%3Cstop offset='1' stop-color='%231e2e2d' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='b' cx='1200' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23274654'/%3E%3Cstop offset='1' stop-color='%23274654' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='c' cx='600' cy='0' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23232830'/%3E%3Cstop offset='1' stop-color='%23232830' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='d' cx='600' cy='800' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23215251'/%3E%3Cstop offset='1' stop-color='%23215251' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='e' cx='0' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23121212'/%3E%3Cstop offset='1' stop-color='%23121212' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='f' cx='1200' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%232D3C56'/%3E%3Cstop offset='1' stop-color='%232D3C56' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='1200' height='800'/%3E%3Crect fill='url(%23b)' width='1200' height='800'/%3E%3Crect fill='url(%23c)' width='1200' height='800'/%3E%3Crect fill='url(%23d)' width='1200' height='800'/%3E%3Crect fill='url(%23e)' width='1200' height='800'/%3E%3Crect fill='url(%23f)' width='1200' height='800'/%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  min-height: calc(100svh - 56px);
}

.link {
  display: flex;
  background: var(--highlight-color);
  margin: 20px 0;
  padding: 15px 25px;
  color: var(--bg-color);
  align-items: center;
  font-size: 0.7rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  transition: 400ms ease-in-out;
  filter: brightness(0.9);
}

.link:hover {
  padding-left: 455px;
  filter: brightness(1);
}

header {
  height: 50px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  cursor: default;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 90rem;
  margin: 0 auto;
  height: 100%;
}

.header-logo {
  font-size: 1.5rem;
  padding: 0 20px;
  letter-spacing: -0.05rem;
  filter: brightness(0.9);
  transition: 200ms;
}

.header-logo:hover {
  filter: brightness(1);
}

.header-logo span {
  color: var(--text-color);
}

.header-nav {
  display: flex;
  height: 100%;
}

.nav-link {
  color: var(--bg-color);
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 25px;
  font-size: 0.7rem;
  font-weight: 500;
  border-left: var(--border);
  text-transform: uppercase;
  background: var(--highlight-color);
  letter-spacing: 0.1rem;
  transition: 200ms;
  border: 1px solid transparent;
  filter: brightness(0.9);
}

.nav-link:hover {
  opacity: 100%;
  filter: brightness(1);
}

.active {
  opacity: 100%;
}

section.hero-section {
  position: relative;
  min-height: 100vh;
}

.hero-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  opacity: 40%;
}

/* .hero-grid-container > div {
  height: 100%;
  width: 100%;
}

.hero-grid-one {
  background-image: url("/public/images/reliable.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  z-index: 1;
  opacity: 0.5;
  filter: blur(0px);
}

.hero-grid-two {
  display: flex;
  justify-content: flex-end;
  background: #242424;
  opacity: 0.5;
  filter: blur(0px);
}

.hero-grid-two > div {
  background-image: url("/public/images/fit.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
} */

.hero-text-container {
  position: absolute;
  inset: 0;
  margin: 0 auto;
  max-width: 72rem;
  border-right: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
  height: 100%;
}

.hero-text {
  margin: 0 auto;
  max-width: 36.5rem;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 3rem;
}

.hero-text > h2 {
  line-height: 5.5rem;
  z-index: 2;
  color: var(--highlight-color);
}

.hero-text > h2 > span {
  color: var(--text-color);
}

.hero-section-two {
  border-bottom: var(--border);
}

.hero-section-two div {
  max-width: 60rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-left: var(--border);
  border-right: var(--border);
}

.hero-section-two div a {
  display: grid;
  place-content: center;
  background: #121212;
  height: 200px;
  opacity: 0.7;
  transition: opacity 100ms;
}

.hero-section-two div a:not(:last-child) {
  border-right: var(--border);
}

.hero-section-two div a:hover {
  background: #242424;
  opacity: 1;
}

.hero-section-three {
  max-width: 60rem;
  margin: 0 auto;
  padding: 2rem;
  border-left: var(--border);
  border-right: var(--border);
}

.hero-section-three div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.services {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--bg-color);
}

.services-h3 {
  font-size: 1.3rem;
  padding-top: 10px;
  opacity: 0.85;
}
#cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

#cards:hover > .card > .card-border {
  opacity: 1;
}

.card {
  background-color: rgba(34, 38, 42, 1);
  height: 270px;
  min-width: 300px;
  flex: 1;
  cursor: pointer;
  border-radius: 0px;
  position: relative;
  color: var(--text-color);
}

.card:hover::before,
.card:hover > .card-border {
  opacity: 1;
}

.card::before,
.card > .card-border {
  border-radius: inherit;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 300ms;
  width: 100%;
  z-index: 2;
}

.card::before {
  background: radial-gradient(
    800px circle at var(--mouse-x) var(--mouse-y),
    rgba(255, 255, 255, 0.06),
    transparent 40%
  );
  z-index: 3;
}

.card > .card-border {
  background: radial-gradient(
    400px circle at var(--mouse-x) var(--mouse-y),
    rgba(255, 255, 255, 0.3),
    transparent 40%
  );
  z-index: 1;
}

.card > .card-content {
  background-color: rgb(34, 38, 42);
  border-radius: inherit;
  height: calc(100% - 4px);
  margin: 2px 1px 2px 1px;
  position: relative;
  width: calc(100% - 2px);
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px;
  gap: 15px;
}

.card > .card-content * {
  transition: 400ms;
  transform: translateY(-6px);
}

.card:hover > .card-content * {
  opacity: 0.9;
}

.gallery-section {
  padding: 20px;
  background: #323232;
}

.yarl__container {
  background: var(--bg-color) !important;
}
.yarl__thumbnails_container {
  background: var(--bg-color) !important;
}
.yarl__thumbnails_vignette {
  background: linear-gradient(
    to right,
    var(--yarl__color_backdrop, var(--bg-color)) 0,
    transparent var(--yarl__thumbnails_vignette_size, 12%)
      calc(100% - var(--yarl__thumbnails_vignette_size, 12%)),
    var(--yarl__color_backdrop, var(--bg-color)) 100%
  ) !important;
}
.yarl__thumbnails_thumbnail {
  border: none !important;
  background: transparent !important;
  border-radius: 0 !important;
}

footer {
  text-align: center;
  padding: 20px;
  opacity: 60%;
  border-top: var(--border);
  letter-spacing: 0.08rem;
  background-color: rgb(34, 38, 42) !important;
  opacity: 1;
}

footer p {
  font-size: 0.7rem;
  opacity: 0.2;
  cursor: default;
}

@media (max-width: 768px) {
  .header-logo {
    font-size: 1rem;
    padding: 0.6rem;
  }

  .header-nav a {
    font-size: 0.7rem;
    padding: 1rem;
  }

  .link:hover {
    padding-left: 190px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .hero-grid-container {
    grid-template-columns: 1fr;
  }

  .hero-grid-container > div {
    height: 300px;
  }

  .hero-grid-two {
    display: none;
  }

  .hero-text {
    max-width: 20rem;
  }

  .hero-text > h2 {
    font-size: 3rem;
    line-height: 3rem;
    padding: 0 10px;
  }

  .hero-section-two div {
    grid-template-columns: 1fr;
  }
}
